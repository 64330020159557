<template>
  <div v-if="simpleTpl" class="area">
      <div class="item">
        <TwoLevelListDTS :tableData="tableDataDTS" nextPage="/aion/store" queryKey="company_id"></TwoLevelListDTS>
      </div>
  </div>
  <div v-else class="area">
      <div class="item">
        <div class="title">得分公式</div>
        <div class="box-total" v-if="scoreData">
          <p>
          <strong class="a">{{scoreData.a}}</strong>
          <span class="a-p">展厅接待得分<i>(A)</i></span>
          </p>
          <p class="flag">=</p>
          <p>
          <strong>{{scoreData.b}}</strong>
          <span>系统使用得分 <i class="i-question" @click="popFormula=true"></i> <i>B ({{scoreData.b_percent}})</i></span>
          </p>
          <p class="flag">+</p>
          <p>
          <strong>{{scoreData.c}}</strong>
          <span>接待质量得分<i>C ({{scoreData.c_percent}})</i></span>
          </p>
        </div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="title">核心指标</div>
        <div class="box-core">
          <div class="main" v-for="(item, index) in entryData" :key="index" >
            <div class="value">
              <strong>{{ item.value }}</strong> {{['组','组','组','分'][index]}}
            </div>
            <div class="label">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="title">经销商排名</div>
        <ul class="list-top">
          <li v-for="(item, idx) in topData">
            <span class="label"><strong>{{idx + 1}}</strong>{{item.label}}</span>
            <div class="box-bar">
              <span class="bara">
              </span>
              <span class="bar"
                :style="`width: ${item.value}%`">
              </span>
            </div>
            <span class="score">{{item.value}}</span>
          </li>
        </ul>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="title">接待量</div>
        <div class="detail">
          <div ref="chartBar" style="width: 100%; height: 5.86666rem" class="chart" ></div>
        </div>
      </div>

      <div class="line"></div>
      <div class="item">
        <ThreeLevelList v-if="threeTpl" :tableData="tableData" :tableQualityData="tableQualityData" nextPage="/aion/store" queryKey="company_id"></ThreeLevelList>
        <TwoLevelList v-else :tableData="tableData" nextPage="/aion/store" queryKey="company_id"></TwoLevelList>
      </div>

      <el-dialog
        title=" "
        :visible.sync="popFormula"
        class="pop-formula" >
         <div class="box-formula">
           <p>{{formula.b_formula}}</p>
           <p>
             <span>人员使用得分=</span>
             <span class="division">
               <strong>{{formula.b1_numerator}}</strong>
               <strong>{{formula.b1_denominator}}</strong>
             </span>
             <span>{{formula.b1_right}}</span>
           </p>
           <p>
             <span>人员匹配得分=</span>
             <span class="division">
               <strong>{{formula.b2_numerator}}</strong>
               <strong>{{formula.b2_denominator}}</strong>
             </span>
             <span>{{formula.b2_right}}</span>
           </p>
         </div>
        <span slot="footer" class="dialog-footer">
        </span>
      </el-dialog>
  </div>
</template>
<script>
import { postData } from "@/api/index";
import echarts from "echarts";
import util from "@/utils/index";
import TwoLevelList from '@/components/TwoLevelList'
import TwoLevelListDTS from '@/components/TwoLevelListDTS'
import ThreeLevelList from '@/components/ThreeLevelList'

export default {
  components: {
    TwoLevelList,
    TwoLevelListDTS,
    ThreeLevelList,
  },

  data() {
    return {
      entryData: [],
      listData: [],
      scoreData: {},
      topData: [],
      formula: {},
      popFormula: false,
      tableData: {},
      tableDataDTS: {},
      simpleTpl: util.isDTS,
      tableQualityData: [],
      threeTpl: util.showThree,
    }
  },
  methods: {
    getQualityData () {
      const query = { is_mobile:1,...this.$route.query, ...this.$store.state.queryDB };
      postData('/api/board/qualityTestingArea', query).then(res => {
        this.tableQualityData = res.data;
      });
    },
    async getDataBySearch() {
      const query = { is_mobile:1,...this.$route.query, ...this.$store.state.queryDB };
      if(query.brand_id) {
        this.simpleTpl = query.brand_id == util.DTS_ID;
        this.threeTpl = util.showThree && (query.brand_id == util.DAZHONG_ID);
      }
      
      this.loading = true;
      const { data = {} } = await postData("/api/board/store", query);
      const {
        list,
        title_hall,
        title_web,
        core,
        top10,
        chart,
        formula,
        ...scoreData
      } = data || {};
 
      this.entryData = [
      { label: "接待量", value: core.count },
        { label: "首次进店量", value: core.first },
        { label: "再次进店量", value: core.again },
        { label: "展厅接待得分", value: core.total },
      ];

      this.topData = top10.map(o => ({label: o.name, value: o.total}));
      this.listData = list;
      this.scoreData = scoreData;
      this.formula = formula;

      this.tableData = util.formatVolvoList(list, title_hall);
      this.tableDataDTS = util.formatVolvoListDTS(list, title_hall);

      const barList = [
        {
          title: "接待量",
          dataList: chart.date.map((o, i) => ({
            label: o.slice(-5) + "周" + chart.week[i].slice(-1),
            value: chart.total[i],
          })),
        },
        {
          title: "首次进店量",
          dataList: chart.date.map((o, i) => ({
            label: o + chart.week[i],
            value: chart.first[i],
          })),
        },
        {
          title: "再次进店量",
          dataList: chart.date.map((o, i) => ({
            label: o + chart.week[i],
            value: chart.again[i],
          })),
        },
      ];
      this.initChart(getBarOpts(barList));
      this.loading = false;

      if (this.threeTpl) {
        this.getQualityData();
      }
    },
    initChart(opts) {
      if (!this.$refs.chartBar) {
        return;
      }
      const charts = echarts.init(this.$refs.chartBar);
      charts.clear();
      charts.setOption(opts);
    },
  },
  mounted() {
    this.getDataBySearch();
  },
};

function getBarOpts(list) {
  const legend = list.map((o) => o.title) || [];
  const labelArr = list[0].dataList.map((o) => o && o.label) || [];
  const series = list.map((o, i) => ({
    name: o.title,
    type: "bar",
    barCategoryGap: '10px',
    barMaxWidth: 10,
    data: o.dataList.map((o) => o && o.value),
  }));

  const barOpts = {
    color: ["#377EEA", "#FAC15C", "#11C16F"],
    grid: {
      left: '30px',
      bottom: '65px'
      
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: '#ffffff',
      extraCssText: 'box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1)',
      textStyle: {
        color: "#000",
        fontSize: '8px',
      },
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: legend,
      icon: 'rect',
      itemHeight: 8,
      itemWidth: 10,
      textStyle: {
        color: "#000",
        fontSize: '8px',
      },
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        data: labelArr,
        axisLabel: {
          interval: 0,
          rotate: labelArr.length > 3 ? 40 : 0,
          textStyle: {
            color: "#000",
            fontSize: '8px',
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          textStyle: {
            color: "#000",
          },
        },
      },
    ],
    dataZoom: [
      {
        show: labelArr.length > 7,
        type: 'slider',
        maxValueSpan: 6,
        height: '10px',
        bottom: 1,
      },
    ],
    series,
  };

  return barOpts;
}
</script>
<style lang="scss" scoped>
@import "@/assets/index.scss";

.list-top {
  li .label {
    width: 3.8rem;
  }
}
</style>



